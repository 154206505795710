<template>
  <div class="container un_select">
    <div class="head">
      <div class="header">
        <div>
          <img src="@/assets/images/complete2.png" alt="" class="img" />
        </div>
        <div class="text1">授权成功</div>
        <div class="text2">
          <span>使用电脑端登录管理台，完成配置后即可使用</span>
        </div>
        <div class="input">
          <input
            type="text"
            value="https://qiwei.xiaoe-tech.com"
            class="websiteText"
            disabled="disabled"
          />
          <input type="button" class="button" value="复制" @click="copyText" />
        </div>
      </div>
      <div class="floatDialog">
        <div class="avatar">
          <img src="@/assets/images/avatar.png" alt="" />
        </div>
        <div class="text1">添加专属顾问，获取1v1服务</div>
        <div class="QRcode">
          <img src="@/assets/images/sale_code.png" alt="" />
        </div>
        <div class="text2">
          <span class="finger_print"
            ><img src="@/assets/images/finger_print.png" alt=""
          /></span>
          <span>长按识别二维码，或保存图片</span>
        </div>
      </div>
    </div>
    <div class="long_pic">
      <img
        src="http://wechatapppro-1252524126.file.myqcloud.com/apprnDA0ZDw4581/image/b_u_5b2225aa46488_oGKN7IvA/knzk7yy00b84.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "settingPage",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    copyText() {
      let textArea = document.createElement("textarea");
      textArea.value = "https://qiwei.xiaoe-tech.com";
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        this.$toast("复制成功");
      } catch (err) {
        this.$toast("复制失败");
      }
      document.body.removeChild(textArea);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .head {
    height: 12.2rem;
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-image: url("../assets/images/blue_background.png");
      width: 100%;
      height: 5.13rem;
      background-size: auto 5.13rem;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 0rem 0rem 0.32rem 0.32rem;
      .img {
        margin-top: 0.64rem;
        margin-bottom: 0.16rem;
      }
      img {
        width: 0.96rem;
        height: 0.96rem;
      }

      .text1 {
        padding-bottom: 0.08rem;
        width: 50%;
        height: 0.56rem;
        font-size: 0.4rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.56rem;
        text-align: center;
      }
      .text2 {
        padding-bottom: 0.24rem;
        height: 0.36rem;
        font-size: 0.24rem;
        font-weight: 400;
        color: #ffffff;
      }
      .input {
        position: relative;
        width: 5.26rem;
        height: 0.56rem;
        border-radius: 0.28rem;
        border: 0.02rem solid #ffffff;
        .websiteText {
          padding-top: 0.1rem;
          padding-left: 0.16rem;
          font-size: 0.24rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.36rem;
          width: 3.28rem;
        }
        .button {
          font-size: 0.28rem;
          font-weight: 500;
          color: #1472ff;
          line-height: 0.4rem;
          position: absolute;
          right: -1px;
          top: 0px;
          width: 1.36rem;
          height: 0.56rem;
          background: #ffffff;
          border-radius: 0.28rem;
        }
      }
    }
    .floatDialog {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 4.04rem;
      left: 50%;
      transform: translateX(-50%);
      width: 6.22rem;
      background: #ffffff;
      box-shadow: 0rem 0.04rem 0.4rem 0rem rgba(0, 0, 0, 0.1);
      border-radius: 0.16rem;
      .avatar {
        margin-top: 0.48rem;
        img {
          width: 1.48rem;
          height: 1.48rem;
        }
      }
      .text1 {
        margin-top: 0.3rem;
        font-size: 0.32rem;
        font-weight: 500;
        color: #333333;
        line-height: 0.48rem;
      }
      .QRcode {
        margin-top: 0.16rem;
        margin-bottom: 0.16rem;
        border-radius: 0.12rem;
        background-color: black;
        img {
          width: 3.2rem;
          height: 3.2rem;
          vertical-align: middle;
        }
      }
      .text2 {
        position: relative;
        margin-bottom: 0.4rem;
        font-size: 0.24rem;
        font-weight: 400;
        color: #1472ff;
        line-height: 0.36rem;
        left: 0.2rem;
        .finger_print {
          position: absolute;
          top: 2px;
          left: -20px;
        }
        img {
          width: 0.32rem;
        }
      }
    }
  }
  .long_pic {
    img {
      width: 100%;
    }
  }
}
</style>
